<template>
    <div class="pageBox">
        <!-- <div class="operbar">
            <div>
                <el-date-picker
                v-model="TimeRang"
                type="daterange"
                range-separator="-"
                format="YYYY-MM-DD"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                />
            </div>
            <el-button class="marginCss" type="primary">生成报告</el-button>
            <el-button type="primary" plain>历史报告</el-button>
        </div> -->
        <div class="topBox">
            <div class="TB-left">
                <div class="titleBox">
                    <img src="../../assets/siteImg/title.png">
                    设备类型占比
                </div>
                <div class="titleMsgBox">
                    <pieChart :data="museumPie" :legend="{show:true, orient:'vertical',right:20,top:'middle'}" name='设备类型数量'/>
                </div>  
            </div>
            <div class="TB-right">
                <div class="titleBox">
                    <img src="../../assets/siteImg/title.png">
                    设备数量在线率概览
                </div>
                <div class="titleMsgBox flexDevice">
                    <div class="itemBox" v-for="(item, index) in deviceCount" :key="index">
                        <div class="itemB-top">
                            <img src="../../assets/siteImg/device.png"/>
                            <div class="itemBT-msg">
                                <div>{{item.name}}</div>
                                <div><span>{{ item.num }}</span>台</div>
                            </div>
                        </div>
                        <div class="progressCss">
                            <el-progress :percentage="item.percent" />
                        </div>
                    </div>
                </div> 
            </div>
        </div>
        <div class="tableBox">
            <div class="titleBox titleCss">
                    <div>
                        <img src="../../assets/siteImg/title.png">
                        设备列表
                    </div>
                    <div>
                        
                    </div>
                </div>
                <div class="titleMsgBox boxPadding">
                    <div style="margin-bottom: 10px;">
                        <el-input placeholder="设备名称" style="width: 200px;margin-right: 10px;" v-model.trim="deviceName" />
                        <el-select v-model="deviceStatus" placeholder="请选择设备状态">
                            <el-option
                            v-for="item in devStatus"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                            />
                        </el-select>
                        <el-button style="margin-left: 10px;" type="primary" @click="CX">查询</el-button>
                    </div>
                    <el-table :data="devTable" border style="width: 100%">
                        <el-table-column prop="deviceName" label="设备名称" />
                        <el-table-column prop="deviceId" label="设备ID"/>
                        <el-table-column label="设备状态">
                            <template #default="scope">
                                <el-tag v-if="scope.row.deviceStatus=='在线'" type="success">在线</el-tag>
                                <el-tag v-if="scope.row.deviceStatus=='低电'" type="warning">低电</el-tag>
                                <el-tag v-if="scope.row.deviceStatus=='离线'" type="danger">离线</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column prop="deviceTypeName" label="设备类型"/>
                        <el-table-column prop="envName" label="所在位置" />
                        <el-table-column label="监测指标">
                            <template #default="scope">
                                <el-tag v-for="item in scope.row.deviceParamsVal" :key="item" >
                                {{ item.envirParamName}}
                                </el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column prop="deviceFactory" label="制造商" />
                        <!-- <el-table-column prop="time" label="校准周期" />
                        <el-table-column prop="minTime" label="校准倒计时" /> -->
                    </el-table>
                </div>
        </div>
    </div>
</template>
<script>
import pieChart from '@/components/charts/pieChart.vue'
import { siteOper_typeCount, siteOper_onlineCount, siteOper_devList1 } from '@/api/site-operationPage.js'
import { mapGetters } from 'vuex'
export default{
    components:{
        pieChart,
    },
    data(){
        return{
            museumName:'',
            TimeRang:null,
            builds:[],
            build:null,
            floors:[],
            currentFloor:null,
            roomId:null,
            museumPie:[],
            deviceCount:[],
            deviceName:null,
            deviceStatus:1,
            devStatus:[
                {label:'在线',value:1},
                {label:'离线',value:0},
                {label:'低电',value:2},
            ],
            devTable:[]

        }
    },
    mounted(){
        this.GetBaseData()
        this.CX()
    },
    computed:{
        ...mapGetters(['siteId'])
    },
    methods:{
        CX(){
            let minQuery = {
                'siteId':this.siteId,
                'envid':this.siteId,
                'deviceName':this.deviceName,
                'deviceStatus':this.deviceStatus,
            }
            siteOper_devList1(minQuery).then( r =>{
                this.devTable = r.data
            })
        },
        GetBaseData(){
            
            let query = {
                'siteId':this.siteId,
            }
            siteOper_typeCount(query).then(r=>{
                this.museumPie = r.data.map(i=>{
                    return { name:i.deviceTypeName , value:i.deviceCount}
                })
                let museumPiedata = r.data
                siteOper_onlineCount(query).then(r=>{
                        this.deviceCount = r.data.map((i,index) => {
        
                        let allCount = museumPiedata.find((el)=>{
                            return el.deviceType == i.deviceType
                        }).deviceCount
                 
                        let percent = allCount==0?0:(i.deviceCount/allCount).toFixed(4)*100
                        let reg1 = new RegExp("监测终端","g"); // 加'g'，删除字符串里所有的"a"
                        let name = i.deviceTypeName.replace(reg1,"");
                        let obj = {
                            'name':name,
                            'num':i.deviceCount,
                            'percent':percent
                        }
                        return obj 
                    })
                })
            })
        },
    }
}
</script>
<style lang="scss" scoped>
.titleMsgBox{
    width: 100%;
    height: calc(100% - 60px);
    position: relative;
}
.returnBtn{
    position: absolute;
    top:10px;
    right:20px;
    z-index: 1016;
}
.roomBox{
    position: absolute;
    top:0;
    right:0;
    width: 100%;
    height: 100%;
    z-index: 1000;
}

.titleCss{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.titleBox{
    width: 100%;
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #CFCFCF;
    box-sizing: border-box;
    padding:0px 20px;
    margin-bottom: 10px;
    img{
        width: 20px;
        height: 30px;
        object-fit: contain;
        vertical-align: middle;
    }
}
.marginCss{
    margin-left: 20px;
}
.pagination{
    margin-top: 5px;
    display: flex;
    justify-content: center;
}
.pageBox{
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
    .operbar{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
}
.topBox{
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: space-between;
    margin-top: 0px;
    .TB-left{
        width: 40%;
        height: 100%;
        background-color: white;
        border-radius: 10px 10px 0px 0px;
    }
    .TB-right{
        width: calc(60% - 20px);
        height: 100%;
        background-color: white;
        border-radius: 10px 10px 0px 0px;
        .flexDevice{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            padding: 0px 20px 20px 20px;
            box-sizing: border-box;
            .itemBox{
                margin-top: 10px;
                margin-right: 10px;
                width: 200px;
                height: 100px;
                border:1px solid rgb(202, 202, 202);
                box-sizing: border-box;
                padding: 5px;
                .itemB-top{
                    width: 100%;
                    height: 70px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    img{
                        width:50px;
                        height: 60px;
                        object-fit: contain;
                    }
                    .itemBT-msg{
                        width: calc(100% - 70px);
                        height: 60px;
                        display: flex;
                        justify-content: space-between;
                        flex-direction: column;
                        font-size: 16px;
                        span{
                            font-size: 28px;
                        }
                    }
                }
                .progressCss{
                    padding-left: 10px;
                    box-sizing: border-box;
                    width: 100%;
                    margin-top: 4px;
                }
            }
        }

    }
}
.tableBox{
    width: 100%;
    min-height: 500px;
    margin-top: 20px;
    background-color: white;
    border-radius: 10px 10px 0px 0px;
    .boxPadding{
        padding: 20px;
        box-sizing: border-box;
    }
}
</style>