<template>
    <div :class="className" :style="{height:height,width:width}" />
  </template>
  <script>
  import * as echarts from 'echarts'
  import { debounce } from '@/utils'
  import { markRaw } from 'vue'
  export default {
    props: {
      className: {
        type: String,
        default: 'chart'
      },
      width: {
        type: String,
        default: '100%'
      },
      height: {
        type: String,
        default: '100%'
      },
      data: {
        type: Array,
        default: ()=>{
          return []
        }
      },
      legend:{
        type:Object,
        default:{
            show:false
        }
      },
      color: {
        type: Array,
        default: ()=>{
          return []
        }
      },
      radius:{
        type:Array,
        default:()=>{
          return ['40%', '70%']
        }
      },
      name:{
        type:String,
        default:'设备类型占比'
      },
    },

    data() {
      return {
        chart:null
      }
    },
    watch:{
      'data':{
        handler() {
           this.refreshData()
        },
        deep:true,
      },
    },
    mounted() {
      this.refreshData()
      this.__resizeHandler = debounce(() => {
        if (this.chart) {
          this.chart.resize()
        }
      }, 500)
      window.addEventListener('resize', this.__resizeHandler)
    },
    beforeUnmount() {
      if (!this.chart) {
        return
      }
      window.removeEventListener('resize', this.__resizeHandler)
      this.chart.dispose()
      this.chart = null
    },
    methods: {
      initChart() {
          this.chart = markRaw(echarts.init(this.$el))
          this.setOption()
      },
      setOption(){
          let _this = this
          this.chart.setOption({
            tooltip: {
                trigger: 'item'
            },
            legend: this.legend,
            series: [
                {
                name: this.name,
                type: 'pie',
                radius: this.radius,
                avoidLabelOverlap: false,
                center:['40%', '50%'],
                itemStyle: {
                    borderRadius: 5,
                    borderColor: '#fff',
                    borderWidth: 2
                },
                label: {
                    show: true,
                    formatter(param) {
                        return param.name + ' (' + param.percent + '%)';
                    }
                },
                labelLine: {
                    show: true
                },
                data: this.data
                }
            ]
          })    
      },
      
      refreshData(){
          //刷新数据
          if(this.chart){
            this.setOption();  
          }else{
            this.initChart()
          }  
      }
    }
  }
  
  </script>